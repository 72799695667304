const config = {
  apiGateway: {
    REGION: 'ap-southeast-2',
    PHY_LOCATION_APIGATEWAY_URL: 'https://yfjxk75jpa.execute-api.ap-southeast-2.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://boj8x8d687.execute-api.ap-southeast-2.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://n0no09zk44.execute-api.ap-southeast-2.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.12.3',
    HOSTNAME: 'https://locations.staging.ozminerals.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.ozminerals.forwoodsafety.com',
    WEBSOCKET: 'wss://hea8dzwrh2.execute-api.ap-southeast-2.amazonaws.com/staging'
  },
};

export default config;